import {
  SupplierConfig,
  defaultSupplierConfig,
  supplierConfigurations,
} from 'src/config/supplierConfigurations';
import { Account } from 'src/interfaces/Account';
import { AppUser } from 'src/interfaces/AppUser';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { AssignedAppUser } from 'src/interfaces/AssignedAppUser';
import { Organization } from 'src/interfaces/Organization';
import { Supplier } from 'src/interfaces/Supplier';
import { useSelector } from 'react-redux';
import moment from "moment-timezone"

export const useUtils = () => {
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );

  const utils = {
    round: (number: number, accuracy: number): number => {
      return parseInt(
        (Math.round(number * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(
          accuracy
        )
      );
    },

    /**
     * Accept a number, and return that number converted to USD currency
     * @param number
     * @param decimalDigits
     * @returns
     */
    toUsd: (number: number, decimalDigits = 2): string => {
      return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalDigits,
        maximumFractionDigits: decimalDigits,
      });
    },

    /**
     * Accept a number, and return that number converted to USD currency
     * @param number
     * @returns
     */
    localizeNumber: (number: number): string => {
      return number.toLocaleString('en-US');
    },

    /**
     * Accept a number up to 10 and returns the roman numeral
     * @param number
     * @returns
     */
    romanize: (number: number): string | typeof NaN => {
      if (number > 10) {
        return NaN;
      }
      const numerals = [
        'I',
        'II',
        'III',
        'IV',
        'V',
        'VI',
        'VII',
        'VIII',
        'IX',
        'X',
      ];
      return numerals[number - 1];
    },

    /**
     * Accept a datestring like "2022-10-11" and convert it into a more readable date string.
     *
     * @param dateString
     * @param options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
     * @returns string
     */
    formatDate: (
      dateString: string,
      options?: Intl.DateTimeFormatOptions
    ): string => {
      const date = new Date(dateString);
      let formatOptions: Intl.DateTimeFormatOptions = options || {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return date.toLocaleDateString('en-US', formatOptions);
    },
    /**
     * Accept a date-time string like "2023-01-16T16:38:49.546" and convert it into a more readable dateTime string.
     *
     * @param dateTimeString
     * @param options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
     * @returns string
     */
    formatDateTime: (
      dateTimeString: string,
      options?: Intl.DateTimeFormatOptions
    ): string => {
      // const mediumTime = new Intl.DateTimeFormat(
      //   'en',
      //   options || {
      //     timeStyle: 'short',
      //     dateStyle: 'short',
      //   }
      // );

      // FIXME: make it dynamic rather then adding 5 hours manually
      // return mediumTime.format(new Date(dateTimeString));
      return moment.utc(dateTimeString).add(5, 'hours').local().format('MM/DD/YY hh:mm A');
    },

    /**
     * Subtract the given number of days, and returns the corresponding date.
     * formatted like "2023-05-16"
     *
     * @param days
     * @returns string
     */
    subDays: (days: number): string => {
      let date = new Date();
      date.setDate(date.getDate() - days);
      return date.toISOString().split('T')[0];
    },

    /**
     * Subtracts the given number of months from today's date, and returns the corresponding date.
     * formatted like "2022-05-16"
     *
     * @param days
     * @returns string
     */
    subMonths: (months: number): string => {
      let date = new Date();
      date.setMonth(date.getMonth() - months);
      return date.toISOString().split('T')[0];
    },

    /**
     * Format an 11-digit NDC
     *
     * @param input string like "12345678987"
     * @returns string like "12345-6789-87"
     */
    formatNdc: (input: string) => {
      if (input.length > 11) {
        console.log(
          'Unable to format ndc string. Reason: String longer than 11 characters',
          input
        );
        return input;
      } else if (input === 'NONE') {
        return '';
      }
      let normalized = input;
      while (normalized.length < 11) {
        normalized = '0' + normalized;
      }
      let ndc = [normalized.slice(0, 5), '-', normalized.slice(5)].join('');
      ndc = [ndc.slice(0, 10), '-', ndc.slice(10)].join('');
      return ndc;
    },
    /**
     * Get supplier configuration an account number based on supplier-specific display rules.
     *
     * @param supplierNumber
     * @returns
     */
    getSupplierConfig: (supplierNumber?: string): SupplierConfig | null => {
      const customConfig = supplierConfigurations.find(
        (config) => config.number === supplierNumber && config.number.length > 0
      );
      if (customConfig) {
        return customConfig;
      }
      return defaultSupplierConfig;
    },

    /**
     * Format an account number based on supplier-specific display rules.
     *
     * @param account
     * @returns
     */
    formatAccount: (account: Account): string => {
      const supplier = allSuppliers?.find(
        (supplier) => supplier.supplier_id === account.supplier_id
      );
      if (supplier) {
        const customConfig =
          supplierConfigurations.find(
            (config) => config.number === supplier.number
          ) || null;
        if (customConfig && customConfig.account.transform) {
          return customConfig.account.transform(account.number);
        }
      }
      return account.number;
    },

    /**
     * @param input string | number like "4365557550"
     * @returns string like "(436) 555-7550"
     */
    formatPhone: (input: string | number): string => {
      /*
    if 10 digits like 4365557550, return "(436) 555-7550"
    if 11 digits like 14365557550, return "+1 (436) 555-7550", where "+1" is the country code
    if 7 digits (unlikely) like 5557550, return "555-7550"
  */
      let stringified = `${input}`;
      let formatted = '';

      if (stringified.length === 11) {
        formatted = stringified.replace(
          /(\d{1})(\d{3})(\d{3})(\d{4})/,
          '+$1 ($2) $3-$4'
        );
      } else if (stringified.length === 10) {
        formatted = stringified.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      } else if (stringified.length === 7) {
        formatted = stringified.replace(/(\d{3})(\d{4})/, '$1-$2');
      } else {
        console.log('Unable to format phone number:', stringified);
        return stringified;
      }
      return formatted;
    },

    /**
     *
     * @param number1
     * @param number2
     * @returns
     */
    calculatePercentDiff: (number1: number, number2: number): number => {
      const diff = number1 - number2;
      const percent = utils.round((diff / number1) * 100, 0);
      return percent;
    },

    /**
     * Accepts 2 JavaScript dates, and returns the difference in days between the two.
     *
     * @param date1
     * @param date2
     * @returns number
     *
     */
    diffInDays: (date1: any, date2: any): number => {
      const diffSeconds = Math.abs(date1 - date2);
      const diffDays = Math.floor(diffSeconds / (1000 * 60 * 60 * 24));
      return diffDays;
    },

    /**
     * Accepts 2 JavaScript dates, and returns the difference in seconds between the two.
     *
     * @param date1
     * @param date2
     * @returns number
     *
     */
    diffInSeconds: (date1: any, date2: any): number => {
      const diffSeconds = Math.abs(date1 - date2);
      return diffSeconds;
    },

    getUserInitials: (firstName: string, lastName: string): string => {
      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    },

    /**
     * Determine if the given user has the Analyst role.
     * @param user AppUser
     * @returns boolean
     */
    isAnalyst: (user: AppUser | AppUserSelf | null): boolean => {
      if (!user) {
        return false;
      }
      return user.app_role_name === 'analyst';
    },
    /**
     * Determine if the given user has the Buyer role.
     * @param user AppUser
     * @returns boolean
     */
    isBuyer: (user: AppUser | AppUserSelf | null): boolean => {
      if (!user) {
        return false;
      }
      return user.app_role_name === 'buyer';
    },

    /**
     * Determine if the given user has the Administrator role
     * @param user AppUser
     * @returns boolean
     */
    isAdmin: (user: AppUser | AppUserSelf | null): boolean => {
      if (!user) {
        return false;
      }
      return user.app_role_name === 'admin';
    },

    /**
     * Determine if the given user has the Super Admin (Application Administrator) role
     * @param user AppUser
     * @returns boolean
     */
    isSuperAdmin: (user: AppUser | AppUserSelf | null): boolean => {
      if (!user) {
        return false;
      }
      return user.app_role_name === 'superadmin';
    },

    /**
     * Determine if the given user belongs to a buyer organization
     * @param user AppUser
     * @returns boolean
     */
    isBuyerOrganization: (organization: Organization | null): boolean => {
      return organization?.organization_type_name === 'buyer';
    },

    /**
     * Determine if the given user belongs to a supplier organization
     * @param user AppUser
     * @returns boolean
     */
    isSupplierOrganization: (organization: Organization | null): boolean => {
      return organization?.organization_type_name === 'supplier';
    },

    /**
     * Format bytes into human readable string
     * @param bytes number
     * @param decimals number
     * @returns
     */
    formatBytes: (bytes: number, decimals = 2): string => {
      if (bytes === 0) return '0 Bytes';
      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    /**
     * Get the user's full name.
     * @param user AppUser | AppUserSelf | AssignedAppUser
     * @returns string
     */
    getFullName: (user: AppUser | AppUserSelf | AssignedAppUser): string => {
      return `${user.first_name} ${user.last_name}`;
    },

    /**
     * Make a CSV string from a multi-dimensional array of strings.
     * @returns string
     */
    makeCsv: (multiDimensionalArray: Array<string[]>): string => {
      return multiDimensionalArray
        .map(
          (row) =>
            row
              .map(String) // convert every value to String
              .map((v) => v.replaceAll('"', '""')) // escape double quotes
              .map((v) => `"${v}"`) // quote it
              .join(',') // comma-separated
        )
        .join('\r\n'); // rows starting on new lines
    },
    /**
     * Convert a string to camel case.
     * ex. 'Customer Email' => 'customerEmail'
     */
    camelCase: (string: string) => {
      return string
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
          return index == 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
    },
  };

  return utils;
};
