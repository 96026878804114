import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonicSlides,
  IonModal,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonRouter
} from '@ionic/react';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '@ionic/react/css/ionic-swiper.css';
import { useSelector } from 'react-redux';
import { ModalSupplierAddSlide1 } from 'src/components/buyer/ModalSupplierAddSlide1';
import { PopoverSupplierMeta } from 'src/components/buyer/PopoverSupplierMeta';
import { FormManageAccounts } from 'src/components/shared/FormManageAccounts';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { Account } from 'src/interfaces/Account';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import 'swiper/css';
import './ModalSupplierAdd.css';
import { ListSupplierContacts } from './ListSupplierContacts';
import { LogoSupplier } from '../shared/LogoSupplier';

export const ModalSupplierAdd: React.FC<{
  isOpen: boolean;
  onClose: any;
  supplier?: Supplier | null;
  onDismiss: (data?: any) => void;
}> = ({ isOpen, onClose, supplier, onDismiss }) => {
  const contactId = useId();
  const router = useIonRouter();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );

  const [recommendedSuppliers, setRecommendedSuppliers] = useState<Supplier[]>(
    []
  );

  const [loading, setLoading] = useState(false);
  const [newAccountIds, setNewAccountIds] = useState<number[]>([]);
  const [submitTrigger, setSubmitTrigger] = useState<number>(0);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const [search, setSearch] = useState<string | null>(null);
  const [standardSuppliersFiltered, setStandardSuppliersFiltered] = useState<
    Supplier[]
  >([]);
  const [featuredSuppliersFiltered, setFeaturedSuppliersFiltered] = useState<
    Supplier[]
  >([]);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );

  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (isOpen && allSuppliers && accounts && selectedLocation) {
      setRecommendedSuppliers(
        allSuppliers.filter(
          (supplier) =>
            accounts.find(
              (account) => account.supplier_id === supplier.supplier_id
            ) === undefined
        )
      );
      slideTo(0, 0);
    }
  }, [isOpen, allSuppliers, accounts, selectedLocation]);

  useEffect(() => {
    filterSuppliers();
  }, [recommendedSuppliers, search]);

  useEffect(() => {
    if (selectedSupplier) {
      if(selectedSupplier.name === "Sandoz"){
        resetModal()
        onClose()
        router.push(`/suppliers/${selectedSupplier.supplier_id}/apply`);
        return
      }
      slideTo(1);
    }
  }, [selectedSupplier]);

  const resetModal = () => {
    setSelectedSupplier(null);
    setNewAccountIds([]);
    setSearched(false);
    setSubmitTrigger(0);
    setSearch(null);
  };

  const filterSuppliers = () => {
    const standard = recommendedSuppliers.filter(
      (supplier) =>
        !supplier.featured &&
        supplier.name.toLowerCase().includes(search?.toLowerCase() || '')
    );
    setStandardSuppliersFiltered(standard);
    const featured = recommendedSuppliers.filter(
      (supplier) =>
        supplier.featured &&
        supplier.name.toLowerCase().includes(search?.toLowerCase() || '')
    );
    setFeaturedSuppliersFiltered(featured);
  };

  const slideTo = (index: number, speed?: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index, speed);
    }
    if (index === 0) {
      resetModal();
    }
  };

  const onActiveIndexChange = (e?: any) => {
    if (swiperInstance) {
      const index = swiperInstance.activeIndex;
      setActiveIndex(index);
    }
  };

  return (
    <IonModal
      className="modalSupplierAdd"
      isOpen={isOpen}
      onIonModalWillDismiss={() => {
        slideTo(0, 0);
        onDismiss();
      }}
      onDidPresent={() => {
        if (supplier) {
          setSelectedSupplier(supplier);
        }
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {activeIndex === 1 && (
              <IonButton onClick={() => slideTo(0)} fill="clear">
                <IonIcon icon={chevronBackOutline} slot="start"></IonIcon>
                Back
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>
            {selectedSupplier
              ? `Add ${selectedSupplier.name} Accounts for ${selectedLocation?.name}`
              : 'Add Supplier'}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClose()}>
              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {activeIndex === 0 ? (
          <IonToolbar>
            <IonSearchbar
              autocomplete="off"
              placeholder="Search for a Supplier..."
              value={search}
              onIonChange={(e) => setSearch(e.detail.value as string)}
              className="ion-no-padding"
            />
          </IonToolbar>
        ) : null}
      </IonHeader>

      <IonContent className="ion-no-padding">
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          modules={[IonicSlides]}
          initialSlide={0}
          speed={400}
          allowTouchMove={false}
          onActiveIndexChange={() => onActiveIndexChange()}
        >
          <SwiperSlide>
            <ModalSupplierAddSlide1
              loading={loading}
              standardSuppliers={standardSuppliersFiltered}
              featuredSuppliers={featuredSuppliersFiltered}
              recommendedSuppliers={recommendedSuppliers}
              setSelectedSupplier={setSelectedSupplier}
            />
          </SwiperSlide>
          <SwiperSlide style={{display: "block"}}>
          <IonRow>
            <IonCol
              size="12"
              className="d-flex ion-align-items-center ion-justify-content-center padding-xs"
            >
              <LogoSupplier src={selectedSupplier?.logo} />
            </IonCol>
          </IonRow>
          <ListSupplierContacts supplier={selectedSupplier!} />
            {/* {selectedSupplier && selectedLocation ? (
              <FormManageAccounts
                loading={loading}
                setLoading={setLoading}
                supplier={selectedSupplier}
                location={selectedLocation}
                newAccountIds={newAccountIds}
                setNewAccountIds={setNewAccountIds}
                searched={searched}
                setSearched={setSearched}
                submitTrigger={submitTrigger}
              />
            ) : null} */}
          </SwiperSlide>
        </Swiper>
      </IonContent>
      {/* {activeIndex === 1 && (
        <>
          <div className="d-flex ion-align-items-center ion-justify-content-start ion-padding">
            <p className="font-size-small ion-no-margin">
              Don't have a {selectedSupplier?.name} direct account yet?{' '}
              {!selectedSupplier?.application_enabled ? (
                <>
                  <IonRouterLink
                    id={contactId}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Contact {selectedSupplier?.name}
                  </IonRouterLink>
                  &nbsp;to request one.
                  {selectedSupplier && (
                    <PopoverSupplierMeta
                      supplier={selectedSupplier}
                      trigger={contactId}
                    />
                  )}
                </>
              ) : null}
            </p>
            {selectedSupplier?.application_enabled ? (
              <IonButton
                size="small"
                fill="outline"
                className="ion-margin-start"
                onClick={onDismiss}
                routerLink={/suppliers/${selectedSupplier.supplier_id}/apply}
                routerDirection="forward"
              >
                Apply Today
              </IonButton>
            ) : null}
          </div>
          <IonFooter>
            <IonToolbar>
              <IonButtons slot="end">
                {loading && (
                  <span className="margin-right-sm">
                    <LoadingInline />
                  </span>
                )}

                <IonButton
                  fill="solid"
                  color="primary"
                  className="ion-margin-horizontal"
                  disabled={loading || newAccountIds.length < 1}
                  onClick={() => setSubmitTrigger(submitTrigger + 1)}
                >
                  Add Account{newAccountIds.length > 1 ? 's' : ''}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </>
      )} */}
    </IonModal>
  );
};