import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonProgressBar,
    IonTitle,
    IonToolbar,
  } from '@ionic/react';
  import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
  import { closeOutline } from 'ionicons/icons';
  import {  useState } from 'react';

  import './ModalPdfImageViewer.css';
  /**
   * file viewer for base64 encoded pdf and image files.
   */
  export const ModalPdfImageViewer: React.FC<{
    isOpen: boolean;
    base64string: string;
    type: string 
    onWillDismiss: () => void;
  }> = ({
    isOpen,
    base64string,
    type,
    onWillDismiss,
  }) => {
    const [loading, setLoading] = useState(true);
    const isImage = type?.includes('image');

    const downloadImage = (base64Image: string) => {
        const link = document.createElement('a'); // Create an anchor element.
        link.href = base64Image; // Set the Base64 string as the href.
        link.download = 'image.png'; // Set the desired file name.
        document.body.appendChild(link); // Append the link to the document.
        link.click(); // Programmatically click the link to trigger the download.
        document.body.removeChild(link); // Clean up by removing the link.
      };

    return (
      <IonModal
        isOpen={isOpen}
        onIonModalWillDismiss={() => {
          onWillDismiss();
        }}
        style={{ '--min-height': '80%', '--width': '800px' }}
        onIonModalDidPresent={() => setLoading(false)}
        className="supplierDocViewer"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {""}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onWillDismiss} fill="clear">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {loading || !base64string  ? (
          <IonProgressBar type="indeterminate" color="primary" />
        ) : (
          <IonContent>
            {
                isImage &&  (
                <div className="viewer-container">
                    <div 
                        onClick={() => downloadImage(base64string)} 
                        className="download-button"
                    >
                        <a>
                        <p>Download</p>
                        </a>
                    </div>
                    <img
                        src={base64string}
                        alt="uploadThumbnail"
                        className="upload-thumbnail"
                    />
                </div>

                )
            }
            {
                type === "application/pdf" && (
                    <PDFViewer
                      data={base64string}
                      defaultZoom={0.95}
                      tools={[
                        'zoomInOut',
                        'zoom',
                        'spacer',
                        'selection',
                        'spacer',
                        'download',
                      ]}
                      saveFileName={'document'}
                    />
                )
            }
          </IonContent>
        )}
      </IonModal>
    );
  };
  