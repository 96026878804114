import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonLabel,
  IonRow,
  IonText,
  IonToolbar,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import {
  addOutline,
  closeCircleOutline,
  peopleOutline,
  saveOutline,
} from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  FormAddress,
  FormLocation,
  LocationSchema,
} from 'src/components/buyer/FormLocation';
import { AddressSchema } from 'src/components/buyer/FormLocation/FormAddress';
import {
  EditLicensesSchema,
  FormLicenses,
} from 'src/components/buyer/FormLocation/FormLicenses';
import { CardActions } from 'src/components/shared/CardActions';
import { useApi } from 'src/hooks/useApi';
import { Address } from 'src/interfaces/Address';
import { License } from 'src/interfaces/License';
import { Location } from 'src/interfaces/Location';
import {
  setContactUsCategory,
  setContactUsOpen,
  setToast,
} from 'src/redux/appReducer';
import { LicenseType } from 'src/types/LicenseTypes';
import './LocationPage.css';

interface LocationUpdateProps {
  name: string;
  description: string | null;
  location_type_id: number;
  location_340b_type_id: number;
  facility_id: string | null;
  department_id: string | null;
  opais_id: string | null;
  gcp: string | null;
  gln: string | null;
  hin: string | null;
}

interface LicenseUpdateProps {
  name: string;
  number: string;
  expiration: string;
  file: string; //base64 encoded string
  filesize: number;
  filename: string;
}
interface LicenseCreateProps extends LicenseUpdateProps {
  location_id: number;
  type: LicenseType;
}

interface AddressUpdateProps {
  name: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country: string | null;
}
interface AddressCreateProps extends AddressUpdateProps {
  organization_id: number;
  location_id: number;
  type: 'bill_to' | 'ship_to';
}

export const LocationPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const api = useApi();
  const locationFormId = useId();
  const licensesFormId = useId();
  const shipToAddressFormId = useId();
  const billingAddressFormId = useId();

  const dispatch = useDispatch();

  const [presentAlert] = useIonAlert();

  // location
  const [location, setLocation] = useState<Location>();
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationChanged, setLocationChanged] = useState(false);
  const [revertLocation, setRevertLocation] = useState(false);

  // licenses
  const [deaLicense, setDeaLicense] = useState<License>();
  const [deaLoading, setDeaLoading] = useState(false);
  const [stateLicense, setStateLicense] = useState<License>();
  const [stateLoading, setStateLoading] = useState(false);
  const [licensesChanged, setLicensesChanged] = useState(false);
  const [revertLicenses, setRevertLicenses] = useState(false);
  const licensesLoading = deaLoading || stateLoading;

  // ship to address
  const [shipToAddress, setShipToAddress] = useState<Address>();
  const [shipToAddressLoading, setShipToAddressLoading] = useState(false);
  const [shipToAddressChanged, setShipToAddressChanged] = useState(false);
  const [revertShipToAddress, setRevertShipToAddress] = useState(false);

  // bill to address
  const [differentBillingAddress, setDifferentBillingAddress] = useState(false);
  const [billingAddress, setBillingAddress] = useState<Address>();
  const [billingAddressLoading, setBillingAddressLoading] = useState(false);
  const [billingAddressChanged, setBillingAddressChanged] = useState(false);
  const [revertBillingAddress, setRevertBillingAddress] = useState(false);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Locations', path: '/settings/locations', direction: 'back' },
    { label: location && location.name ? location.name : id },
  ];

  useIonViewWillEnter(() => {
    getLocation();
  }, [id]);

  useEffect(() => {
    if (location) {
      getAddresses();
      getLicenses();
    }
  }, [location]);

  useEffect(() => {
    if (billingAddress) {
      setDifferentBillingAddress(true);
    } else {
      setDifferentBillingAddress(false);
    }
  }, [billingAddress]);

  const getLocation = () => {
    setLocationLoading(true);
    api
      .get(`location/${id}`)
      .then(({ data }) => {
        setLocation(data);
        setLocationLoading(false);
      })
      .catch((error) => {
        dispatch(
          setToast({
            color: 'danger',
            message: `Error ${error.response.status}. Unable to get this Location`,
          })
        );
        setLocationLoading(false);
      });
  };

  const getLicenses = () => {
    setDeaLoading(true);
    setStateLoading(true);
    api
      .get(`location/${id}/licenses`)
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          const licenses: License[] = data;
          licenses.forEach((license) => {
            if (license.type === 'dea') {
              setDeaLicense(license);
            } else if (license.type === 'state') {
              setStateLicense(license);
            } else {
              console.log('Unknown license type', license);
            }
          });
          setDeaLoading(false);
          setStateLoading(false);
        } else {
          setDeaLoading(false);
          setStateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setDeaLoading(false);
        setStateLoading(false);
      });
  };

  const getAddresses = () => {
    if (location?.address_id) {
      setShipToAddressLoading(true);
      api
        .get(`address/${location.address_id}`)
        .then((response) => {
          if (response.status === 200) {
            const address: Address = response.data;
            setShipToAddress(address);
          }
          setShipToAddressLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShipToAddressLoading(false);
        });
    }
    if (location?.billing_address_id) {
      setBillingAddressLoading(true);
      api
        .get(`address/${location.billing_address_id}`)
        .then((response) => {
          if (response.status === 200) {
            const address: Address = response.data;
            setBillingAddress(address);
          }
          setBillingAddressLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setBillingAddressLoading(false);
        });
    }
  };

  /**
   * Submit the location form
   */
  const onSubmitLocation = (data: LocationSchema) => {
    if (!location) {
      return;
    }
    setLocationLoading(true);
    const formData: LocationUpdateProps = {
      name: data.locationName,
      description: location.description,
      location_type_id: data.locationTypeId,
      location_340b_type_id: data.location340bTypeId,
      facility_id: data.facilityId || null,
      department_id: data.departmentId || null,
      opais_id: data.opaisId,
      gcp: data.gcp || null,
      gln: data.gln || null,
      hin: data.hin || null,
    };
    api
      .put(`location/${location.location_id}`, formData)
      .then(({ data }) => {
        if (data && data.location_id) {
          setLocation(data[0]);
          dispatch(
            setToast({
              message: 'Successfully updated',
            })
          );
          setLocationChanged(false)
          // setEditing(false);
        } else {
          dispatch(
            setToast({
              header: 'Error',
              message: 'There was a problem updating this location',
            })
          );
        }
        setLocationLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLocationLoading(false);
      });
  };

  const onSubmitLicenses = (data: EditLicensesSchema) => {
    const deaFormData: LicenseUpdateProps = {
      name: data.deaLicenseFilename,
      number: data.deaLicenseNum,
      expiration: data.deaLicenseExpiration,
      file: data.deaLicenseFileBase64, // base64 encoded string
      filesize: data.deaLicenseFilesize,
      filename: data.deaLicenseFilename,
    };

    const stateFormData: LicenseUpdateProps = {
      name: data.stateLicenseFilename,
      number: data.stateLicenseNum,
      expiration: data.stateLicenseExpiration,
      file: data.stateLicenseFileBase64, //base64 encoded string
      filesize: data.stateLicenseFilesize,
      filename: data.stateLicenseFilename,
    };
    if (deaLicense) {
      updateLicense(deaLicense, deaFormData);
    } else {
      createLicense('dea', deaFormData);
    }
    if (stateLicense) {
      updateLicense(stateLicense, stateFormData);
    } else {
      console.log('create state license');
      createLicense('state', stateFormData);
    }
  };

  const updateLicense = (license: License, data: LicenseUpdateProps) => {
    if (license.type === 'dea') {
      setDeaLoading(true);
    } else if (license.type === 'state') {
      setStateLoading(true);
    }
    api
      .put(`license/${license.license_id}`, data)
      .then((response) => {
        if (response.status === 200) {
          const updatedLicense: License = response.data;
          if (license.type === 'dea') {
            setDeaLicense(updatedLicense);
            setDeaLoading(false);
          } else if (license.type === 'state') {
            setStateLicense(updatedLicense);
            setStateLoading(false);
          }
          setLicensesChanged(false)
          dispatch(
            setToast({
              message: 'Successfully updated',
            })
          );
        } else {
          dispatch(
            setToast({
              header: 'Error',
              message: 'There was a problem updating this license',
            })
          );
          if (license.type === 'dea') {
            setDeaLoading(true);
          } else if (license.type === 'state') {
            setStateLoading(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (license.type === 'dea') {
          setDeaLoading(true);
        } else if (license.type === 'state') {
          setStateLoading(true);
        }
      });
  };
  const createLicense = (type: LicenseType, data: LicenseUpdateProps) => {
    if (!location) return;
    if (type === 'dea') {
      setDeaLoading(true);
    } else if (type === 'state') {
      setStateLoading(true);
    }
    const formData: LicenseCreateProps[] = [
      {
        location_id: location.location_id,
        type: type,
        ...data,
      },
    ];
    api
      .post(`license`, formData)
      .then((response) => {
        if (response.status === 201) {
          const license: License = response.data[0];
          if (license.type === 'dea') {
            setDeaLicense(license);
            setDeaLoading(false);
          } else if (license.type === 'state') {
            setStateLicense(license);
            setStateLoading(false);
          }
          dispatch(
            setToast({
              message: 'Successfully updated',
            })
          );
        } else {
          dispatch(
            setToast({
              header: 'Error',
              message: 'There was a problem creating this license',
            })
          );
          if (type === 'dea') {
            setDeaLoading(true);
          } else if (type === 'state') {
            setStateLoading(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (type === 'dea') {
          setDeaLoading(true);
        } else if (type === 'state') {
          setStateLoading(true);
        }
      });
  };

  const onSubmitShipToAddress = (data: AddressSchema) => {
    if (!shipToAddress) {
      return;
    }
    const formData: AddressUpdateProps = {
      name: data.addressName || null,
      address1: data.address1 || null,
      address2: data.address2 || null,
      city: data.city || null,
      state: data.state || null,
      postal_code: data.postalCode || null,
      country: 'US',
    };
    setShipToAddressLoading(true);
    api
      .put(`address/${shipToAddress.address_id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          const address: Address = response.data;
          setShipToAddress(address);
          setShipToAddressChanged(false)
          dispatch(
            setToast({
              message: 'Successfully updated',
            })
          );
        } else {
          dispatch(
            setToast({
              header: 'Error',
              message: 'There was a problem updating this address',
            })
          );
        }
        setShipToAddressLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShipToAddressLoading(false);
      });
  };

  const onSubmitBillingAddress = (data: AddressSchema) => {
    if (billingAddress) {
      updateBillingAddress(billingAddress, data);
    } else {
      createBillingAddress(data);
    }
  };
  const updateBillingAddress = (
    billingAddress: Address,
    data: AddressSchema
  ) => {
    const formData: AddressUpdateProps = {
      name: data.addressName || null,
      address1: data.address1 || null,
      address2: data.address2 || null,
      city: data.city || null,
      state: data.state || null,
      postal_code: data.postalCode || null,
      country: 'US',
    };
    setBillingAddressLoading(true);
    api
      .put(`address/${billingAddress.address_id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          const address: Address = response.data;
          setBillingAddress(address);
          setBillingAddressChanged(false)
          dispatch(
            setToast({
              message: 'Successfully updated',
            })
          );
        } else {
          dispatch(
            setToast({
              header: 'Error',
              message: 'There was a problem updating this address',
            })
          );
        }
        setBillingAddressLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setBillingAddressLoading(false);
      });
  };

  const createBillingAddress = (data: AddressSchema) => {
    if (!location) return;
    const formData: AddressCreateProps[] = [
      {
        location_id: location.location_id,
        organization_id: location.organization_id,
        type: 'bill_to',
        name: data.addressName || null,
        address1: data.address1 || null,
        address2: data.address2 || null,
        city: data.city || null,
        state: data.state || null,
        postal_code: data.postalCode || null,
        country: 'US',
      },
    ];
    setBillingAddressLoading(true);
    api
      .post(`address`, formData)
      .then((response) => {
        if (response.status === 201) {
          const address: Address = response.data[0];
          setBillingAddress(address);
          dispatch(
            setToast({
              message: 'Successfully updated',
            })
          );
        } else {
          dispatch(
            setToast({
              header: 'Error',
              message: 'There was a problem updating this address',
              color: 'danger',
            })
          );
        }
        setBillingAddressLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setBillingAddressLoading(false);
      });
  };

  const onClickDeleteBillingAddress = (address: Address) => {
    if (!address) {
      return;
    }
    presentAlert({
      header: 'Remove the Billing Address?',
      message: `The Billing Address "${address?.name}" will be removed.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deleteAddress(address);
        }
      },
    });
  };

  const deleteAddress = (address: Address) => {
    setBillingAddressLoading(true);
    api
      .delete(`address/${address.address_id}`)
      .then((response) => {
        if (response.status === 204) {
          setBillingAddress(undefined);
          if (location) {
            setLocation({ ...location, billing_address_id: null });
          }
          dispatch(
            setToast({
              message: 'Successfully deleted',
            })
          );
        }
        setBillingAddressLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setBillingAddressLoading(false);
      });
  };

  return (
    <Page title="Location Detail" className="locationPage">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Location Detail">
        <IonButton
          size="small"
          id="manage-users-btn"
          disabled={locationLoading || !location}
          routerLink={`/settings/locations/${id}/users`}
          routerDirection="forward"
        >
          Manage Users
          <IonIcon slot="end" icon={peopleOutline} />
        </IonButton>
      </Breadcrumbs>

      <IonRow>
        <IonCol size="12">
          <IonToolbar
            color="warning"
            style={{ borderRadius: 'var(--border-radius)' }}
          >
            <p className="margin-sm text-color-danger">
              Notice: Updates made to the data on this page will not be
              communicated with any existing suppliers you have configured. You
              will need to contact the supplier and provide the data change(s).
              Please allow at least 2 business days before placing any orders for this
              location, to afford supplier sufficient time to make the changes.
            </p>
            <div slot="end" className="ion-margin-horizontal">
              <IonButton
                size="small"
                color="secondary"
                onClick={() => {
                  dispatch(setContactUsOpen(true));
                  dispatch(setContactUsCategory('supplier'));
                }}
              >
                Contact Supplier
              </IonButton>
            </div>
          </IonToolbar>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Facility Info</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <FormLocation
                formId={locationFormId}
                location={location}
                onSubmit={onSubmitLocation}
                loading={locationLoading}
                setChanged={setLocationChanged}
                revertToggle={revertLocation}
              />
            </IonCardContent>
            <CardActions>
              {locationChanged ? (
                <>
                  <IonText color="danger">
                    <p className="ion-no-margin ion-margin-end font-style-italic">
                      Unsaved Changes
                    </p>
                  </IonText>

                  <IonButton
                    fill="clear"
                    color="dark"
                    size="small"
                    disabled={locationLoading || !locationChanged}
                    onClick={() => setRevertLocation(!revertLocation)}
                  >
                    Cancel
                  </IonButton>
                </>
              ) : null}
              <IonButton
                form={locationFormId}
                type="submit"
                disabled={locationLoading || !locationChanged}
                size="small"
              >
                Save Facility Info
                <IonIcon slot="end" icon={saveOutline} />
              </IonButton>
            </CardActions>
          </IonCard>
        </IonCol>

        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <div className="fullHeight d-flex flex-wrap flex-column ion-justify-content-between">
              <div>
                <IonCardHeader>
                  <IonCardTitle>Licensing</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-padding">
                  <FormLicenses
                    formId={licensesFormId}
                    deaLicense={deaLicense}
                    stateLicense={stateLicense}
                    onSubmit={onSubmitLicenses}
                    deaLoading={deaLoading}
                    stateLoading={deaLoading}
                    setChanged={setLicensesChanged}
                    revertToggle={revertLicenses}
                  />
                </IonCardContent>
              </div>
              <CardActions>
                {licensesChanged ? (
                  <>
                    <IonText color="danger">
                      <p className="ion-no-margin ion-margin-end font-style-italic">
                        Unsaved Changes
                      </p>
                    </IonText>
                    <IonButton
                      fill="clear"
                      color="dark"
                      size="small"
                      disabled={licensesLoading || !licensesChanged}
                      onClick={() => setRevertLicenses(!revertLicenses)}
                    >
                      Cancel
                    </IonButton>
                  </>
                ) : null}
                <IonButton
                  form={licensesFormId}
                  type="submit"
                  disabled={licensesLoading || !licensesChanged}
                  size="small"
                >
                  Save Licenses
                  <IonIcon slot="end" icon={saveOutline} />
                </IonButton>
              </CardActions>
            </div>
          </IonCard>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Address (Ship To)</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <FormAddress
                formId={shipToAddressFormId}
                address={shipToAddress}
                onSubmit={onSubmitShipToAddress}
                loading={shipToAddressLoading}
                setChanged={setShipToAddressChanged}
                revertToggle={revertShipToAddress}
              />
            </IonCardContent>
            <CardActions>
              {shipToAddressChanged ? (
                <>
                  <IonText color="danger">
                    <p className="ion-no-margin ion-margin-end font-style-italic">
                      Unsaved Changes
                    </p>
                  </IonText>
                  <IonButton
                    fill="clear"
                    color="dark"
                    size="small"
                    disabled={shipToAddressLoading || !shipToAddressChanged}
                    onClick={() => setRevertShipToAddress(!revertShipToAddress)}
                  >
                    Cancel
                  </IonButton>
                </>
              ) : null}
              <IonButton
                form={shipToAddressFormId}
                type="submit"
                disabled={shipToAddressLoading || !shipToAddressChanged}
                size="small"
              >
                Save Address
                <IonIcon slot="end" icon={saveOutline} />
              </IonButton>
            </CardActions>
          </IonCard>
        </IonCol>

        <IonCol size="12" sizeLg="6">
          {/* IF BILLING ADDRESS EXISTS OR DIFF BILLING ADDRESS IS TOGGLED */}
          {(billingAddress && !billingAddressLoading) ||
            differentBillingAddress ? (
            <>
              <IonCard className="fullHeight">
                <IonCardHeader className="d-flex ion-justify-content-between ion-align-items-start">
                  <IonCardTitle>Billing Address</IonCardTitle>
                  {/* only show if billingAddress exists */}
                  {billingAddress && (
                    <IonButton
                      fill="clear"
                      className="ion-no-padding ion-no-margin"
                      size="small"
                      onClick={() =>
                        onClickDeleteBillingAddress(billingAddress)
                      }
                      data-tooltip-id="global-tooltip"
                      data-tooltip-place="left"
                      data-tooltip-content="Remove Billing Address"
                    >
                      <IonIcon
                        slot="icon-only"
                        color="danger"
                        icon={closeCircleOutline}
                      />
                    </IonButton>
                  )}
                </IonCardHeader>
                <IonCardContent className="ion-padding">
                  <FormAddress
                    formId={billingAddressFormId}
                    address={billingAddress}
                    onSubmit={onSubmitBillingAddress}
                    loading={billingAddressLoading}
                    setChanged={setBillingAddressChanged}
                    revertToggle={revertBillingAddress}
                  />
                </IonCardContent>
                {billingAddress ? (
                  <CardActions>
                    {billingAddressChanged ? (
                      <>
                        <IonText color="danger">
                          <p className="ion-no-margin ion-margin-end font-style-italic">
                            Unsaved Changes
                          </p>
                        </IonText>
                        <IonButton
                          fill="clear"
                          color="dark"
                          size="small"
                          disabled={
                            billingAddressLoading || !billingAddressChanged
                          }
                          onClick={() =>
                            setRevertBillingAddress(!revertBillingAddress)
                          }
                        >
                          Cancel
                        </IonButton>
                      </>
                    ) : null}
                    <IonButton
                      form={billingAddressFormId}
                      type="submit"
                      disabled={billingAddressLoading || !billingAddressChanged}
                      size="small"
                    >
                      Save Billing Address
                      <IonIcon slot="end" icon={saveOutline} />
                    </IonButton>
                  </CardActions>
                ) : (
                  <CardActions>
                    <IonButton
                      fill="clear"
                      color="dark"
                      size="small"
                      disabled={billingAddressLoading}
                      onClick={() => setDifferentBillingAddress(false)}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      form={billingAddressFormId}
                      type="submit"
                      disabled={billingAddressLoading || !billingAddressChanged}
                      size="small"
                    >
                      Save
                      <IonIcon slot="end" icon={saveOutline} />
                    </IonButton>
                  </CardActions>
                )}
              </IonCard>
            </>
          ) : (
            <IonCard
              button
              onClick={() => setDifferentBillingAddress(true)}
              className="addBillingAddressCard fullHeight d-flex"
            >
              <div className="fullHeight fullWidth d-flex ion-align-items-center ion-justify-content-center">
                <IonLabel className="ion-no-margin d-inline-flex ion-align-items-center">
                  Add Billing Address{' '}
                  <IonIcon icon={addOutline} className="ion-margin-start" />
                </IonLabel>
              </div>
            </IonCard>
          )}
        </IonCol>
      </IonRow>
    </Page>
  );
};
